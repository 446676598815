@import "./utils/reset";
@import "./utils/fonts";
@import "./utils/variables";
@import "./utils/utils";
@import "./utils/lenis";

:root {
  --contact-progress: 0;
  --contact-dot-x: 0;
  --contact-dot-y: 0;
}

body {
  overscroll-behavior-y: none !important;
  font-family: "FraktionSans-Light", "Helvetica Neue", sans-serif;
  color: var(--midnight-sky);
  font-weight: 500;
}

main {
  overflow-x: hidden;
}

.h0 {
  font-size: 6.875rem;
  line-height: 0.85;

  @media (min-width: 1100px) {
    font-size: 10rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "NeueuMontreal-BoldSemiSqueezed", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1,
h2,
.h2 {
  font-family: "NeueuMontreal-BoldSqueezed", "Helvetica Neue", sans-serif;
  font-size: 9.5vw;
  line-height: 1.4;

  @media (min-width: 550px) {
    font-size: 3.5rem;
  }

  @media (min-width: 1100px) {
    font-size: 4.75rem;
  }
}

h3,
.h3 {
  font-size: 1.5rem;
  line-height: 1.3;

  @media screen and (min-width: 1100px) {
    font-size: 3.15rem;
  }
}

.mb-0 {
  margin-bottom: 0;
}

[data-main-logo] {
  opacity: 0;
  filter: blur(5px);
  scale: 0.75;
  transform: translateX(-15%) translateY(250%) skew(-10deg, -5deg) rotate(2.5deg);
}

[data-fading-text] {
  font-kerning: none;

  .line {
    display: inline-block;
    opacity: 0;
  }
}

.pixel-font {
  font-family: "Mondwest-Bold", "Helvetica Neue", sans-serif;
  font-size: 1em;
}

.navigation {
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 0;
  z-index: 100;
  width: calc(100% - 3rem);
  height: 3.125rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5rem;
  box-shadow: 0 5px 2rem rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.5rem;
}

@supports ((-webkit-backdrop-filter: none)) {
  .navigation {
    -webkit-backdrop-filter: blur(0.65rem);
  }
}

@supports ((backdrop-filter: none)) {
  .navigation {
    backdrop-filter: blur(0.65rem);
  }
}

.navigation__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  padding: 0 1.5625rem;

  @media (min-width: 550px) {
    max-width: 34rem;
    margin: auto;
  }

  @media (min-width: 1100px) {
    max-width: 48.125rem;
    margin: auto;
  }
}

.container--desktop-wide {
  @media (min-width: 1100px) {
    max-width: 60rem;
  }
}

.container--mob-no-pad {
  padding: 0;
}

.section {
  padding: 5rem 0;
  min-height: 100vh;
  height: auto;
}

.section-intro {
  background-color: var(--midnight-sky);
  padding-top: 0;

  [data-fading-text-block] {
    position: relative;
    opacity: 0;
    transform: translate3d(0, 100px, 1) rotate(2.5deg);
  }
}

.vi-logo {
  width: 12vw;
  max-width: 4.25rem;
  height: auto;
  margin-top: 11.5rem;
  margin-bottom: 8.75rem;
}

.section--invert {
  background-color: var(--midnight-sky);
  color: var(--wall-grey);
}

.shopify-logo {
  display: inline-block;
  align-items: center;
}

.shopify-logo__logo {
  margin-right: -0.5rem;
  width: 7.5ch;
  height: auto;
  clear: left;
  position: relative;
  top: 0.2rem;

  @media (min-width: 550px) {
    max-width: 34rem;
    margin: auto;
    top: 0.3rem;
  }

  @media (min-width: 1100px) {
    max-width: 48.125rem;
    margin: auto;
    top: 0.35rem;
  }

  path {
    fill: var(--shopify-green);
  }
}

.logos-container {
  width: 100%;
  height: 10rem;
  opacity: 1;
  margin: 5rem 0 11rem;
  position: relative;
  pointer-events: none;

  @media (min-width: 1100px) {
    margin: 10rem 0 20rem;
    height: 20rem;
  }
}

.logos {
  display: flex;
  flex-direction: column;
  gap: 3rem 0;
  overflow: hidden;
  width: 150%;
  margin-left: -25%;
  position: absolute;
}

.marquee {
  --gap: 7rem;
  --speed: 50s;
  display: flex;
  user-select: none;
  gap: var(--gap);
  width: 100%;
  margin-bottom: -0.5rem;

  &:nth-child(2) {
    --speed: 35s;
  }

  &:last-child {
    margin-left: -6.5rem;
    --speed: 55s;
  }
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  animation: scroll var(--speed) linear infinite;
  animation-direction: reverse;
  margin: 0;
  padding: 0;
  backface-visibility: hidden;
  will-change: transform;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.angled-top {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0px;
  transform: translateY(-100%);

  svg {
    position: relative;
    top: 1px;
    display: block;
    width: 101%;
    height: auto;
  }
}

.section-services {
  background-color: var(--wall-grey);
  position: relative;
  padding-bottom: 0;

  &::before {
    // content: "";
    position: absolute;
    top: -50px;
    left: 0;
    width: 120%;
    height: 100px;
    background-color: var(--wall-grey);
    z-index: 0;
    transform: rotate(-9deg);
  }

  .services__header {
    margin-top: 2rem;
    margin-bottom: 10rem;
  }
}

.services,
.why-me__blocks {
  padding: 0;
  display: flex;
  flex-direction: column;
  // position: relative;

  .service,
  .why-me__block {
    height: auto;
    padding: 2.1875rem 2.5rem 10rem;
    margin-top: -3rem;
    background-color: var(--deep-lavender);
    flex: 0 0 100%;
    border-radius: 0.4375rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 1;
    top: 0;

    @media (min-width: 1100px) {
      padding: 3.5rem 4rem 10rem;

      p {
        font-size: 1.85rem;
      }
    }

    &[data-service-two] {
      background-color: var(--acid-green);
      z-index: 2;
    }

    &[data-service-three] {
      background-color: var(--skin-beige);
      z-index: 3;
    }
  }
}

.section-contact {
  position: relative;
  z-index: 5;
  display: grid;
  align-items: start;
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: 550px) {
    .container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  [data-animate-text] {
    display: block;
    flex-wrap: wrap;
    opacity: 1;
  }

  .line:last-child {
    .word:last-child {
      .char:last-child {
        color: transparent;
        position: relative;
        height: 0.875rem;
        width: 0.875rem;
        margin-top: auto;
        vertical-align: bottom;
        backface-visibility: hidden;

        &::after {
          content: "";
          position: absolute;
          bottom: 0.4rem;
          left: 0;
          width: 0.875rem;
          height: 0.875rem;
          background: var(--wall-grey);
          z-index: 1;
          border-radius: 50%;
          backface-visibility: hidden;
          transform: translateX(var(--contact-dot-x)) translateY(var(--contact-dot-y))
            scale(calc(1 + var(--contact-progress)));
        }
      }
    }
  }

  .char {
    display: inline-flex;
  }
}

.contact__inner {
  padding-top: 100px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.container-absolute {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  display: grid;
  place-items: center left;

  @media (min-width: 550px) {
    padding: 0;
  }

  h3,
  a {
    font-size: 5.75vw;
    color: var(--midnight-sky);
    margin-bottom: 6.25rem;

    @media (min-width: 550px) {
      font-size: 2rem;
    }

    @media (min-width: 1100px) {
      font-size: 3.15rem;
    }
  }

  a {
    text-decoration: none;
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 0;

    @media (min-width: 1100px) {
      margin-right: 4rem;
    }
  }

  [data-wave-emoji] {
    display: inline-block;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    animation-name: waving-hand;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.3, -0.49, 0.69, 1.15);

    @media (min-width: 1100px) {
      font-size: 4rem;
    }
  }

  [data-linkedin] {
    .icon {
      display: inline-block;
      position: relative;
      bottom: 0.3rem;
      left: -2px;

      @media (min-width: 1100px) {
        bottom: 0.75rem;

        svg {
          width: 1.25rem;
          height: auto;
        }
      }
    }
  }

  [data-clipboard-copy] {
    position: relative;
    display: inline-block;

    .icon {
      @media (min-width: 1100px) {
        svg {
          width: 1.5rem;
          height: auto;
        }
      }
    }

    &.visible {
      .tooltip {
        transform: translateY(-100%) translateX(-0.25rem) scale(1);
      }
    }

    &.copied {
      .tooltip {
        opacity: 1;
      }
    }
  }

  .tooltip {
    width: auto;
    white-space: nowrap;
    font-size: 1.2rem;
    color: var(--wall-grey);
    background-color: var(--midnight-sky);
    padding: 0.7rem 1rem;
    border-radius: 20px;
    position: absolute;
    transform-origin: left center;
    transform: translateY(0%) scale(0.01);
    opacity: 0;
    transition: all 0.2s ease;
  }
}

@keyframes waving-hand {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(14deg);
  }
  10% {
    transform: rotate(-8deg);
  }
  15% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-4deg);
  }
  25% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
