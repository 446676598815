*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-family: NeueuMontreal-BoldSemiSqueezed;
  src: url("OTNeueMontreal-BoldSemiSqueezed.063e342d.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: NeueuMontreal-BoldSqueezed;
  src: url("OTNeueMontreal-BoldSqueezed.47707b07.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Mondwest-Bold;
  src: url("PPMondwest-Bold.af0f53bc.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FraktionSans-Light;
  src: url("PPFraktionSans-Light.ef92a058.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Raster-Roman;
  src: url("FKRasterRomanCompact-Smooth.c6f33d3f.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:root {
  --midnight-sky: #151519;
  --snow-white: #fff;
  --wall-grey: #f8eded;
  --shopify-green: #a1c05d;
  --brat-green: #06d001;
  --salad-green: #9bec00;
  --yellow-green: #f3ff90;
  --acid-green: #89cd5f;
  --skin-beige: #d5b6b6;
  --deep-lavender: #a675d6;
  --speed-multiplier: 1;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.u-color--acid-green {
  color: #89cd5f;
}

.u-color--skin-beige {
  color: #d5b6b6;
}

.u-color--deep-lavender {
  color: #a675d6;
}

html.lenis, html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

:root {
  --contact-progress: 0;
  --contact-dot-x: 0;
  --contact-dot-y: 0;
}

body {
  color: var(--midnight-sky);
  font-family: FraktionSans-Light, Helvetica Neue, sans-serif;
  font-weight: 500;
  overscroll-behavior-y: none !important;
}

main {
  overflow-x: hidden;
}

.h0 {
  font-size: 6.875rem;
  line-height: .85;
}

@media (width >= 1100px) {
  .h0 {
    font-size: 10rem;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 1em;
  font-family: NeueuMontreal-BoldSemiSqueezed, Helvetica Neue, sans-serif;
  font-weight: 500;
}

h1, .h1, h2, .h2 {
  font-family: NeueuMontreal-BoldSqueezed, Helvetica Neue, sans-serif;
  font-size: 9.5vw;
  line-height: 1.4;
}

@media (width >= 550px) {
  h1, .h1, h2, .h2 {
    font-size: 3.5rem;
  }
}

@media (width >= 1100px) {
  h1, .h1, h2, .h2 {
    font-size: 4.75rem;
  }
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.3;
}

@media screen and (width >= 1100px) {
  h3, .h3 {
    font-size: 3.15rem;
  }
}

.mb-0 {
  margin-bottom: 0;
}

[data-main-logo] {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-15%)translateY(250%)skew(-10deg, -5deg)rotate(2.5deg);
}

[data-fading-text] {
  font-kerning: none;
}

[data-fading-text] .line {
  opacity: 0;
  display: inline-block;
}

.pixel-font {
  font-family: Mondwest-Bold, Helvetica Neue, sans-serif;
  font-size: 1em;
}

.navigation {
  z-index: 100;
  background: #ffffff80;
  border: 1px solid #ffffff40;
  border-radius: 5rem;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 3rem);
  height: 3.125rem;
  padding: 0 1.5rem;
  display: flex;
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 0;
  box-shadow: 0 5px 2rem #0000001a;
}

@supports ((-webkit-backdrop-filter: none)) {
  .navigation {
    -webkit-backdrop-filter: blur(.65rem);
  }
}

@supports (backdrop-filter: none) {
  .navigation {
    backdrop-filter: blur(.65rem);
  }
}

.navigation__inner {
  justify-content: center;
  align-items: center;
  display: flex;
}

.container {
  width: 100%;
  padding: 0 1.5625rem;
}

@media (width >= 550px) {
  .container {
    max-width: 34rem;
    margin: auto;
  }
}

@media (width >= 1100px) {
  .container {
    max-width: 48.125rem;
    margin: auto;
  }

  .container--desktop-wide {
    max-width: 60rem;
  }
}

.container--mob-no-pad {
  padding: 0;
}

.section {
  height: auto;
  min-height: 100vh;
  padding: 5rem 0;
}

.section-intro {
  background-color: var(--midnight-sky);
  padding-top: 0;
}

.section-intro [data-fading-text-block] {
  opacity: 0;
  position: relative;
  transform: translate3d(0, 100px, 1px)rotate(2.5deg);
}

.vi-logo {
  width: 12vw;
  max-width: 4.25rem;
  height: auto;
  margin-top: 11.5rem;
  margin-bottom: 8.75rem;
}

.section--invert {
  background-color: var(--midnight-sky);
  color: var(--wall-grey);
}

.shopify-logo {
  align-items: center;
  display: inline-block;
}

.shopify-logo__logo {
  clear: left;
  width: 7.5ch;
  height: auto;
  margin-right: -.5rem;
  position: relative;
  top: .2rem;
}

@media (width >= 550px) {
  .shopify-logo__logo {
    max-width: 34rem;
    margin: auto;
    top: .3rem;
  }
}

@media (width >= 1100px) {
  .shopify-logo__logo {
    max-width: 48.125rem;
    margin: auto;
    top: .35rem;
  }
}

.shopify-logo__logo path {
  fill: var(--shopify-green);
}

.logos-container {
  opacity: 1;
  pointer-events: none;
  width: 100%;
  height: 10rem;
  margin: 5rem 0 11rem;
  position: relative;
}

@media (width >= 1100px) {
  .logos-container {
    height: 20rem;
    margin: 10rem 0 20rem;
  }
}

.logos {
  flex-direction: column;
  gap: 3rem 0;
  width: 150%;
  margin-left: -25%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.marquee {
  --gap: 7rem;
  --speed: 50s;
  -webkit-user-select: none;
  user-select: none;
  gap: var(--gap);
  width: 100%;
  margin-bottom: -.5rem;
  display: flex;
}

.marquee:nth-child(2) {
  --speed: 35s;
}

.marquee:last-child {
  --speed: 55s;
  margin-left: -6.5rem;
}

.marquee__content {
  justify-content: space-between;
  gap: var(--gap);
  animation: scroll var(--speed) linear infinite;
  backface-visibility: hidden;
  will-change: transform;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  animation-direction: reverse;
  display: flex;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.angled-top {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.angled-top svg {
  width: 101%;
  height: auto;
  display: block;
  position: relative;
  top: 1px;
}

.section-services {
  background-color: var(--wall-grey);
  padding-bottom: 0;
  position: relative;
}

.section-services:before {
  background-color: var(--wall-grey);
  z-index: 0;
  width: 120%;
  height: 100px;
  position: absolute;
  top: -50px;
  left: 0;
  transform: rotate(-9deg);
}

.section-services .services__header {
  margin-top: 2rem;
  margin-bottom: 10rem;
}

.services, .why-me__blocks {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.services .service, .services .why-me__block, .why-me__blocks .service, .why-me__blocks .why-me__block {
  background-color: var(--deep-lavender);
  z-index: 1;
  border: 1px solid #0000000d;
  border-radius: .4375rem;
  flex: 0 0 100%;
  height: auto;
  margin-top: -3rem;
  padding: 2.1875rem 2.5rem 10rem;
  top: 0;
}

@media (width >= 1100px) {
  .services .service, .services .why-me__block, .why-me__blocks .service, .why-me__blocks .why-me__block {
    padding: 3.5rem 4rem 10rem;
  }

  .services .service p, .services .why-me__block p, .why-me__blocks .service p, .why-me__blocks .why-me__block p {
    font-size: 1.85rem;
  }
}

.services .service[data-service-two], .services .why-me__block[data-service-two], .why-me__blocks .service[data-service-two], .why-me__blocks .why-me__block[data-service-two] {
  background-color: var(--acid-green);
  z-index: 2;
}

.services .service[data-service-three], .services .why-me__block[data-service-three], .why-me__blocks .service[data-service-three], .why-me__blocks .why-me__block[data-service-three] {
  background-color: var(--skin-beige);
  z-index: 3;
}

.section-contact {
  z-index: 5;
  align-items: start;
  padding-top: 0;
  padding-bottom: 0;
  display: grid;
  position: relative;
}

@media (width >= 550px) {
  .section-contact .container {
    align-items: center;
    height: 100%;
    display: flex;
  }
}

.section-contact [data-animate-text] {
  opacity: 1;
  flex-wrap: wrap;
  display: block;
}

.section-contact .line:last-child .word:last-child .char:last-child {
  color: #0000;
  vertical-align: bottom;
  backface-visibility: hidden;
  width: .875rem;
  height: .875rem;
  margin-top: auto;
  position: relative;
}

.section-contact .line:last-child .word:last-child .char:last-child:after {
  content: "";
  background: var(--wall-grey);
  z-index: 1;
  backface-visibility: hidden;
  width: .875rem;
  height: .875rem;
  transform: translateX(var(--contact-dot-x)) translateY(var(--contact-dot-y)) scale(calc(1 + var(--contact-progress)));
  border-radius: 50%;
  position: absolute;
  bottom: .4rem;
  left: 0;
}

.section-contact .char {
  display: inline-flex;
}

.contact__inner {
  height: 100%;
  padding-top: 100px;
  position: relative;
  overflow: hidden;
}

.container-absolute {
  place-items: center left;
  width: 100%;
  height: 100%;
  margin: auto;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 550px) {
  .container-absolute {
    padding: 0;
  }
}

.container-absolute h3, .container-absolute a {
  color: var(--midnight-sky);
  margin-bottom: 6.25rem;
  font-size: 5.75vw;
}

@media (width >= 550px) {
  .container-absolute h3, .container-absolute a {
    font-size: 2rem;
  }
}

@media (width >= 1100px) {
  .container-absolute h3, .container-absolute a {
    font-size: 3.15rem;
  }
}

.container-absolute a {
  margin-bottom: 0;
  margin-right: 2rem;
  text-decoration: none;
  display: inline-block;
}

@media (width >= 1100px) {
  .container-absolute a {
    margin-right: 4rem;
  }
}

.container-absolute [data-wave-emoji] {
  margin-bottom: 2.5rem;
  font-size: 2.5rem;
  animation-name: waving-hand;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(.3, -.49, .69, 1.15);
  animation-iteration-count: infinite;
  display: inline-block;
}

@media (width >= 1100px) {
  .container-absolute [data-wave-emoji] {
    font-size: 4rem;
  }
}

.container-absolute [data-linkedin] .icon {
  display: inline-block;
  position: relative;
  bottom: .3rem;
  left: -2px;
}

@media (width >= 1100px) {
  .container-absolute [data-linkedin] .icon {
    bottom: .75rem;
  }

  .container-absolute [data-linkedin] .icon svg {
    width: 1.25rem;
    height: auto;
  }
}

.container-absolute [data-clipboard-copy] {
  display: inline-block;
  position: relative;
}

@media (width >= 1100px) {
  .container-absolute [data-clipboard-copy] .icon svg {
    width: 1.5rem;
    height: auto;
  }
}

.container-absolute [data-clipboard-copy].visible .tooltip {
  transform: translateY(-100%)translateX(-.25rem)scale(1);
}

.container-absolute [data-clipboard-copy].copied .tooltip {
  opacity: 1;
}

.container-absolute .tooltip {
  white-space: nowrap;
  width: auto;
  color: var(--wall-grey);
  background-color: var(--midnight-sky);
  transform-origin: 0;
  opacity: 0;
  border-radius: 20px;
  padding: .7rem 1rem;
  font-size: 1.2rem;
  transition: all .2s;
  position: absolute;
  transform: translateY(0%)scale(.01);
}

@keyframes waving-hand {
  0% {
    transform: rotate(0);
  }

  5% {
    transform: rotate(14deg);
  }

  10% {
    transform: rotate(-8deg);
  }

  15% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-4deg);
  }

  25% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}
/*# sourceMappingURL=index.4970b965.css.map */
