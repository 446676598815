:root {
  --midnight-sky: #151519;
  --snow-white: #ffffff;
  --wall-grey: #f8eded;
  --shopify-green: #a1c05d;
  --brat-green: #06d001;
  --salad-green: #9bec00;
  --yellow-green: #f3ff90;

  --acid-green: #89cd5f;
  --skin-beige: #d5b6b6;
  --deep-lavender: #a675d6;

  --speed-multiplier: 1;
}
