@font-face {
  font-family: "NeueuMontreal-BoldSemiSqueezed";
  src: url("../assets/OTNeueMontreal-BoldSemiSqueezed.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueuMontreal-BoldSqueezed";
  src: url("../assets/OTNeueMontreal-BoldSqueezed.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mondwest-Bold";
  src: url("../assets/PPMondwest-Bold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FraktionSans-Light";
  src: url("../assets/PPFraktionSans-Light.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raster-Roman";
  src: url("../assets/FKRasterRomanCompact-Smooth.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
