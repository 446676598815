.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.u-color--acid-green {
  color: #89cd5f;
}

.u-color--skin-beige {
  color: #d5b6b6;
}

.u-color--deep-lavender {
  color: #a675d6;
}
